import { IAppMonitoringClient, IIdGenerator } from "app-domain";
import {
  BreadcrumbProductProps,
  CardName,
  FilterProductsByPlaylistDto,
  FilterValueProductPricePackageProps,
  InstallmentsOptionsFormattedProps,
  Product,
  ProductCardDto,
  SearchResultByTermDto,
  ShippingOptions,
  SortDataType,
  Tag,
  TagIcon,
  TagManager,
} from "typing";
import {
  Amex,
  DefaultCard,
  Diners,
  Elo,
  Hiper,
  Hipercard,
  Jcb,
  Mastercard,
  Visa,
} from "ui";
import { formatCurrency } from "./formatCurrency";
import { getProductImageUrl } from "./getProductImageUrl";

import { descriptionParcelsText } from "./descriptionParcelsText";
import {
  calculateDiscountPercentage,
  getDiscountMessage,
  getDiscountPercentage,
  getDiscountValue,
} from "./discount";
import { formatLocationName } from "./formatLocationName";
import { obfuscate } from "./obfuscate";
import { handlePreloadComponents } from "./performance";
import { serverSideDeviceDetection } from "./serverSideDeviceDetection";
import { valueProduct } from "./valueProduct";

const sortListShipping = (ListData: ShippingOptions[]) => {
  try {
    return ListData?.reduce<SortDataType>(
      (previousItens, currentItem: ShippingOptions) => {
        const itens = previousItens;
        if (currentItem.pickUpInStore) {
          itens.pickUpAtTheStore.push(currentItem);
          return itens;
        }
        itens.delivery.push(currentItem);
        return itens;
      },
      {
        delivery: [],
        pickUpAtTheStore: [],
      }
    );
  } catch {
    return {
      delivery: [],
      pickUpAtTheStore: [],
    };
  }
};

const searchValueInObject = (
  object: { [value: string]: boolean | string | number },
  valueSearch: string | number | boolean
) => {
  const listValues = Object.entries(object);
  return listValues.find((item) => item.includes(valueSearch));
};

const removeItensNullOrUndefinedObject = (obj: object) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) => value !== null && value !== undefined
    )
  );
};

const filterValueProductPricePackage = (
  price?: FilterValueProductPricePackageProps
) => {
  return (
    price?.spotPrice ||
    (price?.priceList !== price?.salePrice
      ? price?.salePrice
      : price?.priceList)
  );
};

const formatUTCToShort = (date: Date) => {
  return String(
    new Date(date).toLocaleDateString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  );
};

const formatDateToShortCrm = (date: Date | "") => {
  const day = date ? date.getDate().toString().padStart(2, "0") : "";
  const month = date ? (date.getMonth() + 1).toString().padStart(2, "0") : "";
  const year = date ? date.getFullYear() : "";

  return date ? `${year}/${month}/${day}` : "";
};

const formatShortenDate = (date: string) => {
  if (date) {
    const dateObj = new Date(date);

    const day = dateObj.getDate().toString().padStart(2, "0");

    const month = dateObj
      .toLocaleString("pt-BR", { month: "short" })
      .replace(/^\w/, (c) => c.toUpperCase());

    const year = dateObj.getFullYear().toString().slice(-2);

    return `${day} ${month.replace(".", ",")} ${year}`;
  }
  return date;
};

const extractTimeFromUTC = (dateTimeString: string) => {
  if (dateTimeString) {
    const dataObj = new Date(dateTimeString);
    const hours = dataObj.getHours();
    const minutes = dataObj.getMinutes();

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }
  return null;
};

const formatDateAndTime = (date: Date) => {
  try {
    if (!(date instanceof Date)) {
      throw new Error("formatDateAndTime: date must be a date");
    }
    return String(
      new Date(date).toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      })
    );
  } catch {
    return "";
  }
};

const dateFormatForCardMessage = (dateTransform: Date) => {
  const updateOnDate = formatUTCToShort(dateTransform);
  const date = new Date().getDate();
  const currentDate = date < 10 ? `0${date}` : date;
  if (currentDate === updateOnDate) {
    return "hoje";
  }
  return updateOnDate;
};

const getMappedCard = (cardName: CardName) => {
  switch (cardName) {
    case "Mastercard":
      return <Mastercard />;
    case "Visa":
      return <Visa />;
    case "Hiper":
      return <Hiper />;
    case "Elo":
      return <Elo />;
    case "Amex":
      return <Amex />;
    case "Jcb":
      return <Jcb />;
    case "Diners":
      return <Diners />;
    case "Hipercard":
      return <Hipercard />;
    default:
      return <DefaultCard />;
  }
};

const brazilianStates = [
  { state: "AC" },
  { state: "AL" },
  { state: "AM" },
  { state: "AP" },
  { state: "BA" },
  { state: "CE" },
  { state: "DF" },
  { state: "ES" },
  { state: "GO" },
  { state: "MA" },
  { state: "MG" },
  { state: "MS" },
  { state: "MT" },
  { state: "PA" },
  { state: "PB" },
  { state: "PE" },
  { state: "PI" },
  { state: "PR" },
  { state: "RJ" },
  { state: "RN" },
  { state: "RO" },
  { state: "RR" },
  { state: "RS" },
  { state: "SC" },
  { state: "SE" },
  { state: "SP" },
  { state: "TO" },
];

const getUserFirstName = (fullname?: string) => {
  const fullName = fullname?.split(" ");

  return fullName?.[0];
};

const getUserSurname = (fullname?: string) => {
  const fullName = fullname?.split(" ");

  return fullName?.[1];
};

const formatCpf = (cpf: string) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

const removeCharactersSpecials = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export type { CardName };

const getPriceList = (
  product: ProductCardDto | Product,
  ignoreDiscountPercentageValidation = false
) => {
  const priceList =
    product?.prices?.[0]?.priceList !== product?.prices?.[0]?.salePrice
      ? product?.prices?.[0]?.priceList ||
        product?.prices?.find(
          (price) =>
            ignoreDiscountPercentageValidation ||
            price?.discountPercentage !== "0"
        )?.priceList ||
        null
      : null;
  return (
    priceList?.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    }) || null
  );
};

const getSalePrice = (
  product: ProductCardDto | Product,
  appMonitoringClient?: IAppMonitoringClient
) => {
  const salePrice =
    product?.prices?.find((price) => price?.packingQuantity === 1)?.salePrice ||
    product?.prices?.[0]?.salePrice ||
    null;

  if (!salePrice) {
    appMonitoringClient?.captureMessage(
      `O produto ${product?.id} não possui salePrice`,
      "warning"
    );
    return null;
  }

  return salePrice?.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
};

const getMaxInstallments = (product: ProductCardDto | Product) => {
  return (
    product?.prices?.find((price) => price?.packingQuantity === 1)
      ?.maxNumberInstallments ||
    product?.prices?.[0]?.maxNumberInstallments ||
    null
  );
};

const getInstallmentValue = (
  product: ProductCardDto | Product,
  appMonitoringClient?: IAppMonitoringClient
) => {
  const priceObject =
    product?.prices?.find((price) => price.packingQuantity === 1) ||
    product?.prices?.[0] ||
    null;

  if (!priceObject?.salePrice) {
    appMonitoringClient?.captureMessage(
      `O produto ${product?.id} não possui salePrice`,
      "warning"
    );
    return null;
  }

  if (!priceObject?.maxNumberInstallments) {
    appMonitoringClient?.captureMessage(
      `O produto ${product?.id} não possui maxNumberInstallments`,
      "warning"
    );
    return null;
  }

  const installment = priceObject.salePrice / priceObject.maxNumberInstallments;
  return installment.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
};

const getInstallmentsText = (
  product: ProductCardDto | Product,
  textOption: 1 | 2 | 3 | 4 = 1
) => {
  return {
    1: `ou até ${getMaxInstallments(product)}x de ${getInstallmentValue(
      product
    )} s/juros`,
    2: `ou até ${getMaxInstallments(product)}x ${getInstallmentValue(
      product
    )} sem juros`,
    3: `ou até ${getMaxInstallments(product)}x ${getInstallmentValue(
      product
    )} s/ juros`,
    4: `ou ${getMaxInstallments(product)}x de ${getInstallmentValue(
      product
    )} s/ juros`,
  }[`${textOption}`];
};

const formatDatePhrase = (
  date: string | undefined,
  type: "full" | "dayAndMonth" = "full"
) => {
  if (date) {
    const data = new Date(date);

    const options: {
      day: "numeric";
      month: "long";
      year?: "numeric";
    } = {
      day: "numeric",
      month: "long",
      ...(type === "full" && { year: "numeric" }),
    };

    return data.toLocaleDateString("pt-BR", options);
  }

  return date;
};

const mapFilterProductsByPlaylistToSearchByTerm = (
  data: FilterProductsByPlaylistDto
): SearchResultByTermDto => {
  return {
    products: data?.products,
    facets: data?.facetsResponse?.aggregations?.topk,
    title: data?.title,
    instructions: data?.instructions,
  };
};

const installmentsOptionsFormatted = ({
  maxNumberOfInstallments,
  totalSalePrice,
  totalSpotPrice,
}: InstallmentsOptionsFormattedProps): string[] => {
  return Array.from({ length: maxNumberOfInstallments || 1 }, (_, index) => {
    const installmentNumber = index + 1;
    const isFirstPositionAtArray = index === 0;
    const installment = Number(
      isFirstPositionAtArray
        ? totalSpotPrice
        : totalSalePrice / installmentNumber
    );
    const installmentPrice = formatCurrency(installment);

    return `${installmentNumber}x de ${installmentPrice} ${
      isFirstPositionAtArray ? "à vista" : "sem juros"
    }`;
  });
};

const getProductBreadcrumbItems = (
  product: Product | null | undefined
  // appMonitoringClient: IAppMonitoringClient
): BreadcrumbProductProps | null => {
  if (!product?.breadCrumbs) {
    return null;
  }

  const {
    breadCrumbs: {
      category: { name: categoryName = "", link: categoryLink = "" },
      fatherCategory: {
        name: fatherCategoryName = "",
        link: fatherCategoryLink = "",
      },
      grandFatherCategory: {
        name: grandFatherCategoryName = "",
        link: grandFatherCategoryLink = "",
      },
    },
    description = "",
  } = product;

  return {
    items: {
      common: [
        {
          description: grandFatherCategoryName,
          url: `/${grandFatherCategoryLink}`,
        },
        {
          description: fatherCategoryName,
          url: `/${fatherCategoryLink}`,
        },
        {
          description: categoryName,
          url: `/${categoryLink}`,
        },
        {
          description,
        },
      ],
      mobile: {
        description: categoryName,
        url: `/${categoryLink}`,
      },
    },
  };
};

const handleProductTag = (
  productId: string,
  productTag: Tag | TagManager | undefined,
  appMonitoringClient: IAppMonitoringClient
) => {
  if (productTag) {
    if (!productTag?.icon) {
      appMonitoringClient.captureMessage(
        `O produto de COD: ${productId} não possui um ícone para a tag customizada.`,
        {
          level: "warning",
          tags: {
            fcx_labs_error_source: "api",
          },
        }
      );
    }

    if (
      productTag?.icon &&
      typeof productTag.icon !== "string" &&
      !(productTag?.icon as TagIcon)?.imageUrl
    ) {
      appMonitoringClient.captureMessage(
        `O produto de COD: ${productId} não possui um link para imagem do ícone da tag customizada.`,
        {
          level: "warning",
          tags: {
            fcx_labs_error_source: "api",
          },
        }
      );
    }
  }
};

interface CartItemsProps {
  productId: string;
  quantity: number;
}

const getProductQuantity = (id: string, cartItems: CartItemsProps[]) => {
  const foundItem = cartItems?.find((item) => item?.productId === String(id));
  return foundItem?.quantity;
};

const formatCountdownTimer = (date: string) => {
  const DAYS_IN_SECONDS = 60 * 60 * 24;
  const HOURS_IN_SECONDS = 60 * 60;
  const MINUTES_IN_SECONDS = 60;

  const dateNow = new Date();
  const futureDate = new Date(date);
  const timestamp = dateNow.getTime();
  const futureTimestamp = futureDate.getTime();

  let diffInSeconds = (futureTimestamp - timestamp) / 1000;

  let days = 0;
  if (futureTimestamp > timestamp) {
    days = Math.floor(diffInSeconds / DAYS_IN_SECONDS);
    diffInSeconds -= days * DAYS_IN_SECONDS;
  } else {
    diffInSeconds = 0;
  }

  const hours = Math.floor(diffInSeconds / HOURS_IN_SECONDS);
  diffInSeconds -= hours * HOURS_IN_SECONDS;

  const minutes = Math.floor(diffInSeconds / MINUTES_IN_SECONDS);
  diffInSeconds -= minutes * MINUTES_IN_SECONDS;

  const seconds = Math.floor(diffInSeconds);

  return { days, hours, minutes, seconds };
};

const getPageCacheControlInfo = (envValue: string | undefined) => {
  const staleWhileRevalidateEnv = Number(envValue);

  const staleWhileRevalidate = !Number.isNaN(staleWhileRevalidateEnv)
    ? staleWhileRevalidateEnv
    : 300;

  const maxAge = staleWhileRevalidate - 30;

  return {
    staleWhileRevalidate,
    maxAge,
  };
};

const generateId = (type: "uuid", idGenerator: IIdGenerator) => {
  if (type === "uuid") {
    return idGenerator.generateUUID();
  }

  return "";
};

const formatEmailToShowOnlyFourCharactersAndDomain = (
  email: string,
  characterSpecial = "*"
) => {
  const regexToGetDomain = /^.{4}.*@(.+)$/;
  const domain = email?.match(regexToGetDomain)?.[1];
  const firstFourthCharactersFromEmail = email?.substring(0, 4);

  const regexToRemoveDomainAndFirstFourDigits = /^.{4}(.+?)@.+/;
  const result = email?.replace(regexToRemoveDomainAndFirstFourDigits, "$1");

  const restOfEmailWithCharacter = characterSpecial.repeat(result?.length);

  return `${firstFourthCharactersFromEmail}${restOfEmailWithCharacter}@${domain}`;
};

const formatTestId = (testId: string) => {
  if (!testId) {
    return "";
  }

  const newTestId = testId.replace(/\s/g, "-");
  return newTestId.toLowerCase();
};

const replaceImageBaseUrl = (imageUrl: string) => {
  return imageUrl?.replace(
    /fercos-backoffice.s3.us-east-1.amazonaws.com|fercos-s3-ecommerce.s3.amazonaws.com/,
    "fcx-s3-fercos-backoffice.ferreiracosta.com"
  );
};

const formatUserName = (clientName?: string) => {
  if (!clientName) return "";

  const names = clientName?.trim()?.split(" ");
  const formattedNames = names.map((name) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  });
  return formattedNames.join(" ");
};

export * from "./anonymizeUserData";
export * from "./branchLocation";
export * from "./buildTestIds";
export * from "./capitalize";
export * from "./formatBranchId";
export * from "./formatDate";
export * from "./formatDueDateCreditCard";
export * from "./formatNumber";
export * from "./formatPhoneNumber";
export * from "./formatStringUrl";
export * from "./geoLocation";
export * from "./getClientBranchId";
export * from "./getComponentTheme";
export * from "./getFileByLink";
export * from "./getMeasuredUnit";
export * from "./getObjectLength";
export * from "./getPaymentIcon";
export * from "./getPaymentOptions";
export * from "./getPhoneNumberInvalidInformation";
export * from "./getSaleChannel";
export * from "./getSmallMappedCard";
export * from "./getSpotPrice";
export * from "./getUserInitials";
export * from "./isObjectEmpty";
export * from "./removeSpecialCharacters";
export * from "./shareText";
export * from "./trackingOrder";
export * from "./getProductPosition";

export {
  brazilianStates,
  calculateDiscountPercentage,
  dateFormatForCardMessage,
  descriptionParcelsText,
  extractTimeFromUTC,
  filterValueProductPricePackage,
  formatCountdownTimer,
  formatCpf,
  formatCurrency,
  formatDateAndTime,
  formatDatePhrase,
  formatDateToShortCrm,
  formatEmailToShowOnlyFourCharactersAndDomain,
  formatLocationName,
  formatShortenDate,
  formatTestId,
  formatUserName,
  formatUTCToShort,
  generateId,
  getDiscountMessage,
  getDiscountPercentage,
  getDiscountValue,
  getInstallmentsText,
  getInstallmentValue,
  getMappedCard,
  getMaxInstallments,
  getPageCacheControlInfo,
  getPriceList,
  getProductBreadcrumbItems,
  getProductImageUrl,
  getProductQuantity,
  getSalePrice,
  getUserFirstName,
  getUserSurname,
  handlePreloadComponents,
  handleProductTag,
  installmentsOptionsFormatted,
  mapFilterProductsByPlaylistToSearchByTerm,
  obfuscate,
  removeCharactersSpecials,
  removeItensNullOrUndefinedObject,
  replaceImageBaseUrl,
  searchValueInObject,
  serverSideDeviceDetection,
  sortListShipping,
  valueProduct,
};
