import {
  buildTestIds,
  filterValueProductPricePackage,
  formatCurrency,
  formatStringUrl,
} from "application";
import { gtag } from "implementations";
import { useCallback, useState } from "react";
import { Bin, theme } from "ui";
import { GiftProducts, ProductInCart } from "typing";
import { Image } from "../../../../../../Image";
import { Link } from "../../../../../../Link";
import {
  BoxImage,
  ButtonIcon,
  ContainerCard,
  DescriptionProduct,
  DescriptionValue,
  ExtraInfo,
  PriceValue,
  ProductInfo,
  SectionDescription,
  TextPrice,
} from "./styles";

export const packageDescriptionUnitySingular = "Caixa";
export const packageDescriptionUnityPlural = "Caixas";

interface ItemProductCartModalProps {
  productId: string;
  quantity?: number;
  // packagePrice: number | undefined;
  productIdCart: number;
  giftProduct?: GiftProducts;
  productInCart?: ProductInCart;
  deleteAction: (idProductCart: number) => void;
  clientId?: number;
}

const ItemProductCartModal = ({
  productId,
  quantity = 1,
  // packagePrice,
  productIdCart,
  deleteAction,
  giftProduct,
  productInCart,
  clientId,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
ItemProductCartModalProps) => {
  const [isDeletingItem, setIsDeletingItem] = useState<boolean>(false);

  const thumbImage = productInCart?.imageUrl;

  // const PACKAGE_CURRENT = data?.prices?.find(
  //   (item) => item?.packingLevel === packagePrice
  // );

  const factPackaged = productInCart?.factPackaged as number;

  const measuredUnity = productInCart?.measuredUnity;

  const FINAL_QUANTITY = factPackaged > 0 ? quantity * factPackaged : quantity;

  const priceProduct =
    filterValueProductPricePackage({
      priceList: productInCart?.priceList || 0,
      salePrice: productInCart?.salePrice || 0,
      spotPrice: productInCart?.spotPrice || 0,
    }) || 0;

  const removeFromCartAnalyticEvent = useCallback(() => {
    if (!productInCart?.id) {
      return;
    }

    gtag?.removeFromCart(
      {
        itemId: productInCart?.productId,
        quantity: quantity as number,
        itemName: productInCart?.description,
        itemBrand: productInCart?.brand,
        itemCategory: productInCart?.categoryName,
        itemVariant: productInCart?.packingLevel as number,
        price: productInCart?.salePrice as number,
      },
      productInCart?.salePrice as number,
      clientId as number
    );
  }, [clientId, productInCart, quantity]);

  const removeItemFromCart = useCallback(() => {
    setIsDeletingItem(true);
    removeFromCartAnalyticEvent();
    deleteAction(productIdCart);
  }, [deleteAction, productIdCart, removeFromCartAnalyticEvent]);

  const isPackage = !!productInCart?.package;

  const getPackagingDescriptionUnity = useCallback(() => {
    if (quantity > 1) {
      return isPackage
        ? packageDescriptionUnityPlural
        : productInCart?.descriptionPackagingUnitPlural;
    }
    return isPackage
      ? packageDescriptionUnitySingular
      : productInCart?.descriptionPackagingUnitSingular;
  }, [isPackage, productInCart, quantity]);

  return (
    <ContainerCard
      {...buildTestIds(`item-product-cart-modal-${productId}}`)}
      isDeleting={isDeletingItem}
    >
      <Link
        {...buildTestIds(`item-product-cart-modal-link-${productId}}`)}
        href={`/produto/${productId}/${formatStringUrl(
          productInCart?.description || giftProduct?.productName || ""
        )}`}
        target="_self"
      >
        <BoxImage
          {...buildTestIds(`item-product-cart-modal-image-${productId}}`)}
        >
          <Image
            {...buildTestIds(`item-product-cart-modal-image-${productId}`)}
            src={
              giftProduct?.imageUrl ||
              thumbImage ||
              "https://fcx-s3-fercos-backoffice.ferreiracosta.com/thumb/default/default_thumb.svg"
            }
            width="100%"
            height="100%"
            alt="Imagem do produto"
            layout="responsive"
          />
        </BoxImage>
      </Link>
      <SectionDescription
        {...buildTestIds(
          `item-product-cart-modal-section-description-${productId}`
        )}
      >
        <ProductInfo
          {...buildTestIds(`item-product-cart-modal-product-info-${productId}`)}
        >
          <Link
            {...buildTestIds(
              `item-product-cart-modal-link-description-${productId}`
            )}
            href={`/produto/${productId}/${formatStringUrl(
              productInCart?.description || giftProduct?.productName || ""
            )}`}
            target="_self"
          >
            <DescriptionProduct
              {...buildTestIds(
                `item-product-cart-modal-description-${productId}`
              )}
            >
              {productInCart?.description || giftProduct?.productName}
            </DescriptionProduct>
          </Link>
          <ButtonIcon
            {...buildTestIds(
              `item-product-cart-modal-button-icon-${productId}`
            )}
            onClick={() => removeItemFromCart()}
          >
            <Bin height={20} width={20} color={theme.colors.neutral["400"]} />
          </ButtonIcon>
        </ProductInfo>

        <ExtraInfo
          {...buildTestIds(`item-product-cart-modal-extra-info-${productId}`)}
        >
          {giftProduct ? (
            <DescriptionValue
              {...buildTestIds(
                `item-product-cart-modal-description-value-${productId}`
              )}
            >
              {quantity} Unidade{quantity > 1 && "s"}
            </DescriptionValue>
          ) : (
            <DescriptionValue
              {...buildTestIds(
                `item-product-cart-modal-description-value-${productId}`
              )}
            >
              {factPackaged > 0
                ? `${quantity} ${
                    quantity > 1
                      ? `embalagens de ${factPackaged}${measuredUnity}`
                      : `embalagem de ${factPackaged}${measuredUnity}`
                  }`
                : `${quantity} ${getPackagingDescriptionUnity()}`}
            </DescriptionValue>
          )}
          <PriceValue
            {...buildTestIds(
              `item-product-cart-modal-price-value-${productId}`
            )}
          >
            {giftProduct ? (
              <TextPrice
                {...buildTestIds(
                  `item-product-cart-modal-text-price-${productId}`
                )}
              >
                {formatCurrency(giftProduct?.price || 0)}
              </TextPrice>
            ) : (
              <>
                <TextPrice
                  {...buildTestIds(
                    `item-product-cart-modal-text-price-${productId}`
                  )}
                >
                  {`${formatCurrency(priceProduct * (FINAL_QUANTITY || 1))} ${
                    productInCart?.spotPrice ? "à vista" : ""
                  }`}
                </TextPrice>
                {productInCart?.salePrice === priceProduct ? null : (
                  <TextPrice
                    {...buildTestIds(
                      `item-product-cart-modal-second-text-price-${productId}`
                    )}
                  >
                    {` ou ${formatCurrency(
                      (productInCart?.salePrice || 0) * (FINAL_QUANTITY || 1)
                    )}`}
                  </TextPrice>
                )}
              </>
            )}
          </PriceValue>
        </ExtraInfo>
      </SectionDescription>
    </ContainerCard>
  );
};

export default ItemProductCartModal;
